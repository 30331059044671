var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(12, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "4" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "my-2 px-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "image", height: "75" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }